import Page from "../../components/Page/Page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

const ContactUs = () => {
  return (
    <Page>
      <section id="contact-us">
        <div class="container-fluid mt-5 py-5">
          <div class="row text-center">
            <h1 class="">CONTACT US</h1>
            <p class="mt-5">Do you have questions? Would you like to share your comments with us ?</p>
            <p class="mt-4">We'd love to hear from you!</p>
            <p class="mt-4">You can leave us a message on our social media accounts, it is the best way to reach us quickly!</p>
            <p>
              <a class="text-dark mt-5 text-decoration-none">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="" class="text-dark text-decoration-none mt-5 ms-3">
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
              <a href="" class="text-dark text-decoration-none mt-5 ms-3">
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
            </p>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default ContactUs;
