const AboutUs = () => {
  return (
    <>
      <section id="about-us-section">
        <div className="container-fluid mt-md-5">
          <h1 className="text-center">THEY TALK ABOUT US</h1>
          <div className="row">
            <div className="col-md-4 mt-2 mt-md-5">
              <h4 className="text-center text-dark">FACEBOOK</h4>
              <div className="row">
                <div className="col-md-12 mt-2 mt-md-3 text-center">
                  <a href="" className="text-decoration-none">
                    <div className="card-body">
                      <img src="/assets/img/facebook.png" alt="facebook" className="img-fluid" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2 mt-md-5">
              <h4 className="text-center text-dark">TIKTOK</h4>
              <div className="row">
                <div className="col-md-12 mt-2 mt-md-3 text-center">
                  <a href="" className="text-decoration-none">
                    <div className="card-body">
                      <img src="/assets/img/facebook.png" alt="facebook" className="img-fluid" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2 mt-md-5">
              <div className="row">
                <h4 className="text-center text-dark">INSTAGRAM</h4>
                <div className="col-md-12 mt-2 mt-md-3 text-center">
                  <a href="" className="text-decoration-none">
                    <div className="card-body">
                      <img src="/assets/img/instagram.png" alt="facebook" className="img-fluid" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5"></div>
    </>
  );
};
export default AboutUs;
