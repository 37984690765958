import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  token: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, {payload}) => {
        state.user = payload
    },
    setToken: (state, {payload}) => {
        state.token = payload;
        window.localStorage.setItem('token', payload);
    },
    logout: (state) => {
        state = initialState
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setToken } = userSlice.actions

export default userSlice.reducer