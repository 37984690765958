import Page from "../../components/Page/Page";

const Howitworks = () => {
  return (
    <Page>
      <section id="">
        <div className="container mt-5">
          <h1 className="text-center">YOUR SHOPPING EXPERIENCE AT VENTE PRIVEE</h1>
          <p className="text-center mt-4">
            In this video, find out what you can expect when shopping at Vente Privee. From getting notified about our next sale, discovering pictures and videos of our stock on social media, to the
            booking of your visit on our website and finally shopping at our location.
          </p>
          <div className="row mt-4">
            <div className="col-md-12">
              <video width="100%" height="100%" autoPlay muted controls loop>
                <source src="/assets/videos/banner-1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <h1 className="text-center mt-5">HOW TO BOOK A TIME SLOT</h1>
          <p className="text-center mt-4">
            Booking your time slot is easy! First, ensure that you are registered as a member on our website to access the sales. In this video, learn step by step how to book your time slot to attend
            one of our sales.
          </p>
          <div className="row mt-4">
            <div className="col-md-12">
              <video width="100%" height="100%" autoPlay muted controls loop>
                <source src="/assets/videos/banner-1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <h1 className="text-center mt-5">RULES OF THE SALE</h1>
          <ol className="mt-3 d-grid gap-5 mt-5">
            <li style={{ fontSize: "20px" }} className="marker-bullets">
              All sales are final. No reimbursements. Customers are responsible for checking the visual aspect of their purchased products and that no product is missing before leaving the store. No
              claim will be accepted.
            </li>
            <li style={{ fontSize: "20px" }} className="marker-bullets">
              For safety reasons, we advise you to come without kids. Baby strollers are not allowed in the sale.
            </li>
            <li style={{ fontSize: "20px" }} className="marker-bullets">
              The guest will have to register at the onsite registration station posted at the entrance of the sale before being allowed to access the sale.
            </li>
            <li style={{ fontSize: "20px" }} className="marker-bullets">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo
            </li>
          </ol>
          <div className="row mt-5">
            <div className="col-md-6 d-flex flex-column">
              <div className="img-section flex-fill d-grid gap-3">
                <div>
                  <img src="/assets/img/who_we_are.png" alt="sale" className="img-fluid w-100 h-75" />
                  <a href="./signup.html" className="btn btn-dark position-relative ms-3 btn-lg rounded-0" style={{ marginTop: "-200px" }}>
                    BECOME A MEMBER
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-section flex-fill d-grid gap-3">
                <div>
                  <img src="/assets/img/who_we_are.png" alt="sale" className="img-fluid w-100 h-75" />
                  <a href="./signup.html" className="btn btn-dark position-relative ms-3 btn-lg rounded-0" style={{ marginTop: "-200px" }}>
                    CHECK OUT OUR SALES
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default Howitworks;
