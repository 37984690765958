const PastSales = () => {
  return (
    <section id="past-sales-section">
      <div className="container-fluid mt-2 mt-md-2">
        <div className="row mt-0 mt-md-5">
          <div className="col-md-12">
            <h1 className="text-center mb-5">PAST SALES</h1>
            <ul className="nav justify-content-center mb-5">
              <li className="nav-item">
                <h4 className="font-weight-bold">
                  <a href="" className="nav-link text-muted text-decoration-none">
                    2023
                  </a>
                </h4>
              </li>
              <li className="nav-item">
                <h4 className="font-weight-bold">
                  <a href="" className="nav-link text-dark text-decoration-underline">
                    2022
                  </a>
                </h4>
              </li>
            </ul>
            <div className="row">
              <div className="col-md-4">
                <a href="" className="text-decoration-none">
                  <div className="card border-0 text-center">
                    <div className="card-body p-0">
                      <img src="/assets/img/March2022.png" className="img-fluid" alt="" />
                    </div>
                    <div className="card-footer bg-white text-center border-0">
                      <p className="">March 2022</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="" className="text-decoration-none">
                  <div className="card border-0 text-center">
                    <div className="card-body p-0">
                      <img src="/assets/img/April2022.png" className="img-fluid" alt="" />
                    </div>
                    <div className="card-footer bg-white text-center border-0">
                      <p className="">April 2022</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="" className="text-decoration-none">
                  <div className="card border-0 text-center">
                    <div className="card-body p-0">
                      <img src="/assets/img/May2022.png" className="img-fluid" alt="" />
                    </div>
                    <div className="card-footer bg-white text-center border-0">
                      <p className="">May 2022</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="" className="text-decoration-none">
                  <div className="card border-0 text-center">
                    <div className="card-body p-0">
                      <img src="/assets/img/June2022.png" className="img-fluid" alt="" />
                    </div>
                    <div className="card-footer bg-white text-center border-0">
                      <p className="">June 2022</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="" className="text-decoration-none">
                  <div className="card border-0 text-center">
                    <div className="card-body p-0">
                      <img src="/assets/img/september2022.png" className="img-fluid" alt="" />
                    </div>
                    <div className="card-footer bg-white text-center border-0">
                      <p className="">September 2022</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="" className="text-decoration-none">
                  <div className="card border-0 text-center">
                    <div className="card-body p-0">
                      <img src="/assets/img/september2022.png" className="img-fluid" alt="" />
                    </div>
                    <div className="card-footer bg-white text-center border-0">
                      <p className="">October 2022</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PastSales;
