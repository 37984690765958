import Page from "../../components/Page/Page"

const RegistrationSuccess = () => {
    return (
        <Page>
            <div className="container-fluid">
                <div className="row">
                    <h1 className="text-center">We have send you a verification email</h1>
                </div>
            </div>
        </Page>
    )
}

export default RegistrationSuccess;