import AboutUs from "../../components/AboutUs/AboutUs";
import EventCarousel from "../../components/EventCarousel/EventCarousel";
import Page from "../../components/Page/Page";
import PastSales from "../../components/PastSales/PastSales";

const Home = () => {
  return (
    <Page>
      <section id="video-section">
        <div className="container-fluid mt-5">
          <div className="text-center">
            <video width="100%" height="100%" autoPlay muted controls loop>
              <source src="/assets/videos/banner-1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      <section id="next-sale-section" className="mb-0">
        <div className="container-fluid mt-2 mt-md-5 hg">
          <EventCarousel />
        </div>
      </section>
      <PastSales />
      <AboutUs />
    </Page>
  );
};

export default Home;
