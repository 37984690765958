import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer style={{ backgroundColor: "black" }} className="p-5 mt-3 mt-md-5 mb-0">
        <div className="container text-white">
          <div className="row">
            <div className="col-md-3">
              <img src="/assets/img/logo-black-bg.png" alt="privee" className="img-fluid" />
              <p className="mt-4">PRIVATE SALES OF BRANDED SHOES AND CLOTHES FOR THE WHOLE FAMILY</p>
            </div>
            <div className="col-md-3">
              <h6 className="mt-3 offset-md-2 fw-bold">FOLLOW US</h6>
              <a className="text-decoration-none text-white offset-md-2" href="#">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a className="text-decoration-none text-white ms-2 offset-md-2" href="#">
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
              <a className="text-decoration-none text-white ms-2 offset-md-2" href="#">
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
            </div>
            <div className="col-md-3">
              <h6 className="mt-3 fw-bold">SUPPORT</h6>
              <ul className="list-unstyled mt-4">
                <li className="mb-2">
                  <Link tag={Link} to="/contactus" className="text-decoration-none text-white">
                    CONTACT
                  </Link>
                </li>
                <li className="mb-2">
                  <a href="/faq" className="text-decoration-none text-white">
                    FAQ
                  </a>
                </li>
                <li className="mb-2">
                  <a href="./privacy_policy.html" className="text-decoration-none text-white">
                    PRIVACY POLICY
                  </a>
                </li>
                <li>
                  <a href="./terms_condition.html" className="text-decoration-none text-white">
                    TERMS & CONDITIONS
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h6 className="mt-3 fw-bold">COMPANY</h6>
              <ul className="list-unstyled mt-4">
                <li className="mb-2">
                  <a href="/whoweare" className="text-decoration-none text-white">
                    WHO WE ARE
                  </a>
                </li>
                <li className="">
                  <a href="./career.html" className="text-decoration-none text-white">
                    CAREERS
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div style={{ backgroundColor: "#ccc" }}>
        <p className="text-center mb-0">
          <small className="text-center">&copy; 2023 COPYRIGHT VENTE PRIVEE INC.</small>
        </p>
      </div>
    </>
  );
};

export default Footer;
