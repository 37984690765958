import { useEffect, useRef, useState } from 'react';
import Page from "../../components/Page/Page";
import { BASE_URL } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser, setToken } from '../../redux/slices/userSlice';

const SignIn = () => {
  const formRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);

    const data = {
      email: formData.get('email'),
      password: formData.get('password'),
      remember_me: formData.get('remember_me'),
    }

    setSubmitting(true)

    axios.post(`${BASE_URL}/user-login`, data)
      .then((response) => {
        setSubmitting(false)
        toast.success(`Login successfully`);
        dispatch(setUser(response.data.data['current-user']))
        dispatch(setToken(response.data.data['token']))
        // navigate('/registrationsuccess')
        
      })
      .catch((error) => {
        setSubmitting(false)
        const { errors } = error.response.data;

        Object.keys(errors).forEach((fieldName) => {
          errors[fieldName].forEach((errorMessage) => {
            toast.error(`${fieldName}: ${errorMessage}`);
          });
        });
      });
    }
  


  return (
    <Page>
      <section id="sign-in">
        <div className="container-fluid mt-5">
          <div className="row mt-5 sign-up-form-section">
            <div className="col-md-6">
              <img src="/assets/img/signin-side-img.jpg" alt="sale" className="img-fluid h-75 w-100" />
            </div>
            <div className="col-md-6 px-4">
              <div className="d-flex align-items-end gap-3">
                <h2>ACCESS MY</h2>
                <img src="/assets/img/logo-dark.svg" alt="privee" width="200px" />
                <h2>ACCOUNT</h2>
              </div>
              <div className="row mt-4">
                <h6>Enter your details below. (* required fields)</h6>
              </div>
              <form onSubmit={handleSubmit} ref={formRef}>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="mb-3">
                    <input type="email" className="form-control rounded-0 p-3" placeholder="Email Address *" name='email'/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="mb-3">
                    <input type="password" className="form-control rounded-0 p-3" placeholder="Password *" name='password'/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <input type="checkbox" name="remember_me"/>
                  <label htmlFor="" className="ms-2 class text-muted ms-0" style={{ fontSize: "16px", marginLeft: "-2px !important" }}>
                    Remember me
                  </label>
                </div>
                <div className="col-md-6 mt-3 text-end">
                  <a href="" className="text-muted text-decoration-none">
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-5">
                  <button type="submit" className={`btn btn-dark rounded-0 py-3 px-4 ${submitting ? 'disabled' : ''}`}>
                    {submitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'SIGN IN'}
                  </button>
                </div>
              </div>
              </form>
              <div className="row mt-4"></div>
              <hr className="" />
              <div className="row mt-3">
                <div className="col-md-12 mt-2">
                  <a href="./signup.html" className="text-decoration-none text-dark fw-bold" style={{ fontSize: "18px" }}>
                    Don't have an account yet?
                  </a>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 mt-2">
                  <a href="" className="">
                    <small>SUBSCRIBE</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default SignIn;
