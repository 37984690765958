import { useState } from "react";
import Page from "../../components/Page/Page";

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Nav,
    NavItem,
    TabContent,
    TabPane
  } from 'reactstrap';
import { NavLink } from "react-router-dom";

  

const FAQ = () => {
    const [open, setOpen] = useState('1');
    const [activeTab, setActiveTab] = useState('1');
    
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
    return (
        <Page>
            <section id="contact-us">
            <div className="container-fluid mt-5 py-5">
            <h1 class="text-center">FREQUENTLY ASKED QUESTIONS</h1>
            <div className="row mt-5">
            <div className="col-md-4 mt-5">
            <Nav vertical className="nav-underline flex-column gap-3">
            <NavItem>
                <NavLink className={`text-dark ${activeTab === '1' ? 'text-decoration-underline fw-bold' : 'text-decoration-none'}`} onClick={() => setActiveTab('1')}>
                ACCESS TO THE SALES
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={`text-dark ${activeTab === '2' ? 'text-decoration-underline fw-bold' : 'text-decoration-none'}`} onClick={() => setActiveTab('2')} href="#">
                PURCHASES
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={`text-dark text-decoration-none ${activeTab === '3' ? 'text-decoration-underline fw-bold' : 'text-decoration-none'}`} onClick={() => setActiveTab('3')} href="#">
                ACCOUNT AND PERSONAL INFORMATION
                </NavLink>
            </NavItem>
            </Nav>
            </div>
            <div className="col-md-8 mt-5">
            <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem className="">
                    <AccordionHeader targetId="1">Are the sales open to the public?</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores aliquam quia, explicabo similique natus soluta voluptatibus atque impedit tenetur, numquam dolore ab modi, inventore iure officiis repudiandae enim cum! Quaerat repellat maiores dolores magnam illo asperiores molestiae quod adipisci! Quam porro expedita quo veritatis temporibus architecto nisi corrupti. Quibusdam autem distinctio impedit tempora aliquid repudiandae nulla pariatur iure. Quam ea soluta doloribus, nesciunt rerum quod inventore aliquid modi iusto autem magnam, illo ad dolorem eum! Ratione nobis aspernatur doloremque magnam nulla dicta, tempore laudantium numquam laborum illo distinctio dolorum autem ab recusandae odio fugit totam ipsum. Odit molestias perspiciatis voluptatum!</p>
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="2">What size do you carry?</AccordionHeader>
                    <AccordionBody accordionId="2">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi explicabo laudantium voluptatibus iste error placeat dolorum dicta maxime laborum fugit, cumque sapiente ratione quae minus assumenda adipisci illo ab harum officia omnis porro fugiat a accusantium provident. Necessitatibus asperiores nulla exercitationem error harum possimus perspiciatis amet laudantium blanditiis maxime nobis quidem hic voluptatem non quod porro itaque quas, vero est consequatur ipsam pariatur? Libero aut ullam, magni consectetur dolor nemo unde officiis quod voluptatibus nostrum! Saepe vel, architecto fugit quia natus animi laboriosam, similique iure quasi facilis mollitia quis ducimus soluta. Quod, voluptas tenetur impedit sapiente dolorem culpa quis error.</p>
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="3">Are kids allowed in the sales?</AccordionHeader>
                    <AccordionBody accordionId="3">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi explicabo laudantium voluptatibus iste error placeat dolorum dicta maxime laborum fugit, cumque sapiente ratione quae minus assumenda adipisci illo ab harum officia omnis porro fugiat a accusantium provident. Necessitatibus asperiores nulla exercitationem error harum possimus perspiciatis amet laudantium blanditiis maxime nobis quidem hic voluptatem non quod porro itaque quas, vero est consequatur ipsam pariatur? Libero aut ullam, magni consectetur dolor nemo unde officiis quod voluptatibus nostrum! Saepe vel, architecto fugit quia natus animi laboriosam, similique iure quasi facilis mollitia quis ducimus soluta. Quod, voluptas tenetur impedit sapiente dolorem culpa quis error.</p>
                    </AccordionBody>
                    </AccordionItem>
                </Accordion>
                </TabPane>
                <TabPane tabId="2">
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem className="">
                    <AccordionHeader targetId="1">Are the sales open to the public 2222?</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores aliquam quia, explicabo similique natus soluta voluptatibus atque impedit tenetur, numquam dolore ab modi, inventore iure officiis repudiandae enim cum! Quaerat repellat maiores dolores magnam illo asperiores molestiae quod adipisci! Quam porro expedita quo veritatis temporibus architecto nisi corrupti. Quibusdam autem distinctio impedit tempora aliquid repudiandae nulla pariatur iure. Quam ea soluta doloribus, nesciunt rerum quod inventore aliquid modi iusto autem magnam, illo ad dolorem eum! Ratione nobis aspernatur doloremque magnam nulla dicta, tempore laudantium numquam laborum illo distinctio dolorum autem ab recusandae odio fugit totam ipsum. Odit molestias perspiciatis voluptatum!</p>
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="2">What size do you carry?</AccordionHeader>
                    <AccordionBody accordionId="2">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi explicabo laudantium voluptatibus iste error placeat dolorum dicta maxime laborum fugit, cumque sapiente ratione quae minus assumenda adipisci illo ab harum officia omnis porro fugiat a accusantium provident. Necessitatibus asperiores nulla exercitationem error harum possimus perspiciatis amet laudantium blanditiis maxime nobis quidem hic voluptatem non quod porro itaque quas, vero est consequatur ipsam pariatur? Libero aut ullam, magni consectetur dolor nemo unde officiis quod voluptatibus nostrum! Saepe vel, architecto fugit quia natus animi laboriosam, similique iure quasi facilis mollitia quis ducimus soluta. Quod, voluptas tenetur impedit sapiente dolorem culpa quis error.</p>
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="3">Are kids allowed in the sales?</AccordionHeader>
                    <AccordionBody accordionId="3">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi explicabo laudantium voluptatibus iste error placeat dolorum dicta maxime laborum fugit, cumque sapiente ratione quae minus assumenda adipisci illo ab harum officia omnis porro fugiat a accusantium provident. Necessitatibus asperiores nulla exercitationem error harum possimus perspiciatis amet laudantium blanditiis maxime nobis quidem hic voluptatem non quod porro itaque quas, vero est consequatur ipsam pariatur? Libero aut ullam, magni consectetur dolor nemo unde officiis quod voluptatibus nostrum! Saepe vel, architecto fugit quia natus animi laboriosam, similique iure quasi facilis mollitia quis ducimus soluta. Quod, voluptas tenetur impedit sapiente dolorem culpa quis error.</p>
                    </AccordionBody>
                    </AccordionItem>
                </Accordion>
                </TabPane>
                <TabPane tabId="3">
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem className="">
                    <AccordionHeader targetId="1">Are the sales open to the public 3333?</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores aliquam quia, explicabo similique natus soluta voluptatibus atque impedit tenetur, numquam dolore ab modi, inventore iure officiis repudiandae enim cum! Quaerat repellat maiores dolores magnam illo asperiores molestiae quod adipisci! Quam porro expedita quo veritatis temporibus architecto nisi corrupti. Quibusdam autem distinctio impedit tempora aliquid repudiandae nulla pariatur iure. Quam ea soluta doloribus, nesciunt rerum quod inventore aliquid modi iusto autem magnam, illo ad dolorem eum! Ratione nobis aspernatur doloremque magnam nulla dicta, tempore laudantium numquam laborum illo distinctio dolorum autem ab recusandae odio fugit totam ipsum. Odit molestias perspiciatis voluptatum!</p>
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="2">What size do you carry?</AccordionHeader>
                    <AccordionBody accordionId="2">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi explicabo laudantium voluptatibus iste error placeat dolorum dicta maxime laborum fugit, cumque sapiente ratione quae minus assumenda adipisci illo ab harum officia omnis porro fugiat a accusantium provident. Necessitatibus asperiores nulla exercitationem error harum possimus perspiciatis amet laudantium blanditiis maxime nobis quidem hic voluptatem non quod porro itaque quas, vero est consequatur ipsam pariatur? Libero aut ullam, magni consectetur dolor nemo unde officiis quod voluptatibus nostrum! Saepe vel, architecto fugit quia natus animi laboriosam, similique iure quasi facilis mollitia quis ducimus soluta. Quod, voluptas tenetur impedit sapiente dolorem culpa quis error.</p>
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="3">Are kids allowed in the sales?</AccordionHeader>
                    <AccordionBody accordionId="3">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi explicabo laudantium voluptatibus iste error placeat dolorum dicta maxime laborum fugit, cumque sapiente ratione quae minus assumenda adipisci illo ab harum officia omnis porro fugiat a accusantium provident. Necessitatibus asperiores nulla exercitationem error harum possimus perspiciatis amet laudantium blanditiis maxime nobis quidem hic voluptatem non quod porro itaque quas, vero est consequatur ipsam pariatur? Libero aut ullam, magni consectetur dolor nemo unde officiis quod voluptatibus nostrum! Saepe vel, architecto fugit quia natus animi laboriosam, similique iure quasi facilis mollitia quis ducimus soluta. Quod, voluptas tenetur impedit sapiente dolorem culpa quis error.</p>
                    </AccordionBody>
                    </AccordionItem>
                </Accordion>
                </TabPane>
                </TabContent>
            </div>
            </div>
                </div>
            </section>
        </Page>

    )
}

export default FAQ;