import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Whoweare from "./pages/Whoweare";
import Sales from "./pages/Sales";
import Howitworks from "./pages/Howitworks";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ContactUs from "./pages/ContactUs/ContactUs";
import RegistrationSuccess from "./pages/RegistrationSuccess/Index";
import FAQ from "./pages/Faq";
import Profile from "./pages/Profile";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { BASE_URL } from "./config";
import { setUser } from "./redux/slices/userSlice";



const App = () => {
  const [token, setToken] = useState(window.localStorage.getItem('token'));
  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      axios
      .get(`${BASE_URL}/current-user`, {
        headers:{
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        // console.log(response.data.data)
        dispatch(setUser(response.data.data))
        dispatch(setToken(token))
        
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }
  }, [token]);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/whoweare" element={<Whoweare />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/howitworks" element={<Howitworks />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/registrationsuccess" element={<RegistrationSuccess />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </>
  );
};

export default App;
