import { useEffect, useRef, useState } from 'react';
import Page from "../../components/Page/Page";
import axios from 'axios';
import { BASE_URL } from '../../config';
import Joi from 'joi';
import { setErrorMessages } from '../../helpers/validate';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

const SignUp = () => {
  const [errors, setErrors] = useState([]);
  const [shoeSizes, setShoeSizes] = useState([]);
  const [garmentSizes, setGarmentSizes] = useState([]);
  const [gender, setGender] = useState('F');
  const formRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);

    // Perform your form submission logic here
    const data = {
      first_name: formData.get('first_name'),
      last_name: formData.get('last_name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      postal_code: formData.get('postal_code'),
      password: formData.get('password'),
      confirm_password: formData.get('confirm_password'),
      shoe_size_id: formData.get('shoe_size_id'),
      garment_size_id: formData.get('garment_size_id'),
      have_kids: formData.get('have_kids'),
      dob: formData.get('dob'),
    }

    const { error } = schema.validate(data, {
      abortEarly: false,
  });

  if (error) {
    const t = setErrorMessages(error);
    return setErrors(t);
}

    setSubmitting(true)
    // Make API call
    axios.post(`${BASE_URL}/user-register`, data)
      .then((response) => {
        setSubmitting(false)
        toast.success(`Registration done successfully`);
        navigate('/registrationsuccess')
        
      })
      .catch((error) => {
        setSubmitting(false)
        const { errors } = error.response.data;

        Object.keys(errors).forEach((fieldName) => {
          errors[fieldName].forEach((errorMessage) => {
            toast.error(`${fieldName}: ${errorMessage}`);
          });
        });
      });
    }

  useEffect(() => {
    const fetchShoeSizes = () => {
      axios
        .post(`${BASE_URL}/shoe-sizes`, {
          gender: gender,
        })
        .then((response) => {
          setShoeSizes(response.data['data']);
          
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    fetchShoeSizes();
  }, [gender]);

  const handleGenderChange = (value) => {
    setGender(value); // Update the gender state when radio button is clicked
  };

  useEffect(() => { 
    axios
      .get(`${BASE_URL}/garment-sizes`)
      .then((response) => {
        setGarmentSizes(response.data['data'])
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const schema = Joi.object({
    email: Joi.string().required().email({ tlds: { allow: false } }),
    first_name: Joi.string().required().min(3).messages({
      "string.empty": '"First Name" is required',
      'string.min': '"First Name" must have a minimum length of 3 characters',
    }),
    last_name: Joi.string().required().min(3).messages({
      "string.empty": '"Last Name" is required',
      'string.min': '"Last Name" must have a minimum length of 3 characters',
    }),
    phone: Joi.string().regex(/^[0-9]{10}$/).messages({'string.pattern.base': `"Phone number" must have 10 digits.`}).required(),
    postal_code : Joi.string().regex(/^[0-9]{7}$/).messages({'string.pattern.base': `"Postal Code" must have 7 digits.`}).required(),
    password : Joi.string().min(8).max(191).required(),
    confirm_password: Joi.string().valid(Joi.ref('password')).required().messages({ 
      'string.empty': '"Confirm Password" Field is required',
      'any.only': '"Confirm Password" must match Password',
    }),
    shoe_size_id: Joi.any().optional(),
    garment_size_id: Joi.any().optional(),
    have_kids: Joi.any().optional(),
    // dob: Joi.any().optional(),
    dob: Joi.date()
    .max('12-31-2018')
    .optional()
    .allow(null)
    .allow('')
    .messages({
      'date.base': 'Date of birth must be a valid date',
      'date.max': 'Date of birth must be before 12-31-2018',
    }),
    // dob: Joi.date()
    // .max('now')
    // .subtract(8, 'years')
    // .optional()
    // .messages({
    //   'date.base': 'Date of birth must be a valid date',
    //   'date.max': 'Minimum age required is 8 years old',
    //   'any.required': 'Date of birth is required',
    // }),
  });


  return (
    <Page>
      
      <section id="signup-section">
        <div className="container-fluid mt-5">
          <div className="row sign-up-form-section">
            <div className="col-md-6">
              <img src="/assets/img/signup-side-img.jpg" alt="sale" className="img-fluid h-75 w-100" />
            </div>
            <div className="col-md-6 d-flex flex-column">
              <div className="p-3 flex-fill">
                <div className="d-flex align-items-end gap-3">
                  <h2>CREATE A</h2>
                  <img src="/assets/img/logo-dark.svg" alt="privee" width="200px" />
                  <h2>ACCOUNT</h2>
                </div>
                <p className="mt-4">Enter your personal details below to join the Vente Privee's community and receive invitations to our sales. (* required fields)</p>
                <form onSubmit={handleSubmit} ref={formRef}>

                
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="female" onChange={() => handleGenderChange('F')} defaultChecked={gender == 'F'} />
                      <label className="form-check-label" htmlFor="female">
                        F<small className="text-danger">*</small>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="male" onChange={() => handleGenderChange('M')} defaultChecked={gender == 'M'} />
                      <label className="form-check-label" htmlFor="male">
                        M<small className="text-danger">*</small>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <input type="text" className="form-control rounded-0 p-3" placeholder="First Name *" name="first_name" />
                      {errors.first_name && <span className='text-danger'>{errors.first_name}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <input type="text" className="form-control rounded-0 p-3" placeholder="Last Name *" name="last_name" />
                      {errors.last_name && <span className='text-danger'>{errors.last_name}</span>}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="mb-3">
                      <input type="text" className="form-control rounded-0 p-3" placeholder="Email Address *" name="email" />
                      {errors.email && <span className='text-danger'>{errors.email}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <input type="text" className="form-control rounded-0 p-3" placeholder="Phone Number *" name="phone" />
                      {errors.phone && <span className='text-danger'>{errors.phone}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <input type="text" className="form-control rounded-0 p-3" placeholder="Postal Code *" name="postal_code" />
                      {errors.postal_code && <span className='text-danger'>{errors.postal_code}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <input type="password" className="form-control rounded-0 p-3" placeholder="Password *" name='password' />
                      {errors.password && <span className='text-danger'>{errors.password}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <input type="password" className="form-control rounded-0 p-3" placeholder="Confirm Password *" name='confirm_password'/>
                      {errors.confirm_password && <span className='text-danger'>{errors.confirm_password}</span>}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <span>Tell us about you</span>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <label htmlFor="">Shoe size</label>
                      
                      <select className="form-select rounded-0 p-3 mt-2" name='shoe_size_id' id='shoe_size_id'>
                        <option value="" selected>Select</option>
                      {shoeSizes.map((size) => (
          <option key={size.id} value={size.id}>
            {size.value}
          </option>
          
        ))}
                        
                      </select>
                      {errors.shoe_size_id && <span className='text-danger'>{errors.shoe_size_id}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <label htmlFor="">Clothing size (ex: sweater, jacket)</label>
                      <select className="form-select rounded-0 p-3 mt-2" name='garment_size_id' id='garment_size_id'>
                      <option selected>Select</option>
                      {garmentSizes.map((item) => (
          <option key={item.id} value={item.id}>
            {item.size}
          </option>
        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <label htmlFor="">I have kids under 12 years old</label>
                      <select className="form-select rounded-0 p-3 mt-2" name='have_kids' id='have_kids'>
                        <option selected>Select</option>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="mb-3">
                      <label htmlFor="">Date of birth (for special promotions)</label>
                      <input type="date" className="form-control rounded-0 date-select mt-2" style={{ paddingTop: "16px", paddingBottom: "15px" }} placeholder="Email Address *" name='dob' />
                      {errors.dob && <span className='text-danger'>{errors.dob}</span>}
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <small className="fst-italic text-muted">
                    By creating your account, you agree to Vente Privee’s <a href="">terms and conditions</a> and <a href="">privacy policy</a>.
                  </small>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-5">
                  <button type='submit' className={`btn btn-dark rounded-0 py-3 px-4 ${submitting ? 'disabled' : ''}`}>
                    {submitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'CREATE MY ACCOUNT'}
                  </button>

                  </div>
                </div>
                </form>
                <div className="row mt-4"></div>
                <hr className="" />
                <div className="row mt-3">
                  <div className="col-md-12 mt-2">
                    <a href="./login.html" className="text-decoration-none text-dark fw-bold" style={{ fontSize: "18px" }}>
                      Already have a Privee account?
                    </a>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 mt-2">
                    <a href="./login.html" className="">
                      <small>LOGIN</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default SignUp;
