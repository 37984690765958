import { useSelector } from "react-redux";
import Page from "../../components/Page/Page";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import Joi from 'joi';
import { toast } from "react-toastify";
import { setErrorMessages } from '../../helpers/validate';



const Profile = () => {
    const user = useSelector(store => store.user.user);
    const token = useSelector(store => store.user.token);
    const [gender, setGender] = useState('');
    const [shoeSizes, setShoeSizes] = useState([]);
    const [garmentSizes, setGarmentSizes] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState([]);
    const formRef = useRef();
    
    const fetchShoeSizes = () => {
      axios
        .post(`${BASE_URL}/shoe-sizes`, {
          gender: gender,
        })
        .then((response) => {
          setShoeSizes(response.data['data']);
          
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    useEffect(() => { 
        axios
          .get(`${BASE_URL}/garment-sizes`)
          .then((response) => {
            setGarmentSizes(response.data['data'])
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }, []);

    useEffect(() => {
        if (user?.gender) {
            setGender(user?.gender)
        }
    }, [user])

    useEffect(() => {
        console.log(gender);
        fetchShoeSizes();
      }, [gender]);
    

    const handleGenderChange = (value) => {
        setGender(value); // Update the gender state when radio button is clicked
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(formRef.current);

        const data = {
            first_name: formData.get('first_name'),
            last_name: formData.get('last_name'),
            email: formData.get('email'),
            // phone: formData.get('phone'),
            // postal_code: formData.get('postal_code'),
            // password: formData.get('password'),
            // confirm_password: formData.get('confirm_password'),
            // shoe_size_id: formData.get('shoe_size_id'),
            // garment_size_id: formData.get('garment_size_id'),
            // have_kids: formData.get('have_kids'),
            // dob: formData.get('dob'),
          }

          
      
        
      console.log(token)

      setSubmitting(true)
      axios.patch(`${BASE_URL}/update-user/` + user?.id, data, {
        headers:{
            'Authorization': `Bearer ${token}`
          }
      })
      .then((response) => {
        console.log(response)
        setSubmitting(false)
        toast.success(`Details update successfully`);
        // navigate('/registrationsuccess')
        
      })
      .catch((error) => {
        setSubmitting(false)
        // const { errors } = error.response.data;

        Object.keys(errors).forEach((fieldName) => {
          errors[fieldName].forEach((errorMessage) => {
            toast.error(`${fieldName}: ${errorMessage}`);
          });
        });
      });

    //   axios.post(`${BASE_URL}/user-register`, data)
    //   .then((response) => {
    //     setSubmitting(false)
    //     toast.success(`Registration done successfully`);
    //     navigate('/registrationsuccess')
        
    //   })
    //   .catch((error) => {
    //     setSubmitting(false)
    //     const { errors } = error.response.data;

    //     Object.keys(errors).forEach((fieldName) => {
    //       errors[fieldName].forEach((errorMessage) => {
    //         toast.error(`${fieldName}: ${errorMessage}`);
    //       });
    //     });
    //   });
    

    }
    
    return (
        <Page>
            <section id="my-account">
      <div className="container-fluid mt-5">
        <div className="row sign-up-form-section">
          <h1 className="text-center" style={{ fontFamily: 'Be Vietnam Pro', letterSpacing: '-3px' }}>My Account</h1>
          <p className="text-center text-uppercase">{user?.name}!</p>
          <div className="col-md-6" style={{ marginTop: '60px' }}>
            <span style={{ fontFamily: 'Cambo' }} className="border border-dark h5 p-2">My Personal Details</span>
            <form onSubmit={handleSubmit} ref={formRef}>
              <div className="row mt-5">
                {gender && <div className="col-md-12">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" id="female" onChange={() => handleGenderChange('F')} defaultChecked={gender == 'F'}/>
                    <label className="form-check-label" htmlFor="female">F <small className="text-danger">*</small></label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" id="male" onChange={() => handleGenderChange('M')} defaultChecked={gender == 'M'} />
                    <label className="form-check-label" htmlFor="male">M <small className="text-danger">*</small></label>
                  </div>
                </div>}
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <div className="mb-3">
                    <input type="text" className="form-control rounded-0 p-3" placeholder="First Name *" id="first_name" name="first_name" defaultValue={user?.first_name} />
                    {/* {errors.first_name && <span className='text-danger'>{errors.first_name}</span>} */}
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="mb-3">
                    <input type="text" className="form-control rounded-0 p-3" placeholder="Last Name *" id="last_name" name="last_name" defaultValue={user?.last_name} />
                    {/* {errors.last_name && <span className='text-danger'>{errors.last_name}</span>} */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="mb-3">
                    <input type="text" className="form-control rounded-0 p-3" placeholder="Email Address *" id="email" name="email" defaultValue={user?.email} />
                    {/* {errors.email && <span className='text-danger'>{errors.email}</span>} */}
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 mt-3">
                  <div className="mb-3">
                    <label htmlFor="">Shoe size</label>
                    <select className="form-select rounded-0 p-3 mt-2" name="shoe_size_id" value={user?.shoe_size_id}>
                      <option>Select</option>
                      {shoeSizes.map((size) => (
          <option key={size.id} value={size.id}>
            {size.value}
          </option>
          
        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="mb-3">
                    <label htmlFor="">Clothing size (ex: sweater, jacket)</label>
                    <select className="form-select rounded-0 p-3 mt-2" name="garment_size_id" value={user?.garment_size_id}>
                    <option>Select</option>
                      {garmentSizes.map((item) => (
          <option key={item.id} value={item.id}>
            {item.size}
          </option>
        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <div className="mb-3">
                    <label htmlFor="">I have kids under 12 years old</label>
                    <select className="form-select rounded-0 p-3 mt-2" name='have_kids' value={user?.have_kids}>
                      <option>Select</option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="mb-3">
                    <label htmlFor="">Date of birth (for special promotions)</label>
                    <input type="date" className="form-control rounded-0 date-select mt-2" style={{ marginTop: '2px', paddingTop: '17px', paddingBottom: '13px' }} name='dob' defaultValue={user?.dob} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <button type='submit' className={`btn btn-dark rounded-0 py-2 px-5 ${submitting ? 'disabled' : ''}`}>
                        {submitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'UPDATE MY PERSONAL DETAILS'}
                    </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 px-4" style={{ marginTop: '60px' }}>
            <span style={{ fontFamily: 'Cambo', marginTop: '-25px !important' }} className="border border-dark h5 p-2">Password</span>
            <div className="row" style={{ marginTop: '47px' }}>
              <div className="col-md-12" style={{ marginTop: '42px !important' }}>
                <div className="mb-3">
                  <input type="password" className="form-control rounded-0 p-3" placeholder="Previous Password *" required />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="mb-3">
                  <input type="password" className="form-control rounded-0 p-3" placeholder="New Password *" required />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="mb-3">
                  <input type="password" className="form-control rounded-0 p-3" placeholder="Confirm Password *" required />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-2">
                <button type='submit' className={`btn btn-dark rounded-0 py-2 px-4 ${submitting ? 'disabled' : ''}`}>
                    {submitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'UPDATE PASSWORD'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        </Page>
    )
}

export default Profile;