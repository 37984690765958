import AboutUs from "../../components/AboutUs/AboutUs";
import Page from "../../components/Page/Page";

const Whoweare = () => {
  return (
    <Page>
      <section id="next-sale-section">
        <div className="container-fluid mt-5">
          <div className="row mt-5">
            <div className="col-md-4 d-flex flex-column">
              <div className="img-section flex-fill">
                <div>
                  <img src="/assets/img/who_we_are.png" alt="sale" className="img-fluid w-100" />
                  <a href="./signup.html" className="btn btn-dark position-relative ms-3 btn-lg rounded-0" style={{ marginTop: "-200px" }}>
                    BECOME A MEMBER
                  </a>
                </div>
                <div>
                  <img src="/assets/img/who_we_are.png" alt="sale" className="img-fluid w-100" />
                  <a href="./signup.html" className="btn btn-dark position-relative ms-3 btn-lg rounded-0" style={{ marginTop: "-200px" }}>
                    CHECKOUT OUR SALES
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8 d-flex flex-column">
              <div className="px-3">
                <h3 className="text-center">SMART SHOPPING AT VENTE PRIVEE</h3>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <div className="d-grid">
                      <div>
                        <div className="row">
                          <div className="col-md-2">
                            <img src="/assets/img/gift-icon.png" alt="gift" />
                          </div>
                          <div className="col-md-10 mt-2">
                            <h6>Exclusive discounts</h6>
                            <p className="mt-3">
                              Enjoy up to <strong>80%</strong> OFF your favorite shoes and clothes brands!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <div className="row">
                          <div className="col-md-2">
                            <img src="/assets/img/smiley.png" alt="smiley" />
                          </div>
                          <div className="col-md-10 mt-2">
                            <h6>text here</h6>
                            <p className="mt-3">text here text here text here text here text here text here</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-grid">
                      <div>
                        <div className="row">
                          <div className="col-md-2">
                            <img src="/assets/img/lock.png" alt="lock" />
                          </div>
                          <div className="col-md-10 mt-2">
                            <h6>Members only</h6>
                            <p className="mt-3">
                              Enjoy up to <strong>80%</strong> OFF your favorite shoes and clothes brands!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <div className="row">
                          <div className="col-md-2">
                            <img src="/assets/img/barcode.png" alt="smiley" />
                          </div>
                          <div className="col-md-10 mt-2">
                            <h6>text here</h6>
                            <p className="mt-3">text here text here text here text here text here text here</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5" style={{ textAlign: "justify" }}>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.
                    Fusce posuere,
                  </p>
                  <p className="mt-5">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.
                    Fusce posuere,
                  </p>
                  <p className="mt-5">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.
                    Fusce posuere,
                  </p>
                  <p className="mt-5">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna.
                    Fusce posuere,
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row mt-md-5"></div>
      <div class="row mt-md-5"></div>

      <AboutUs />
    </Page>
  );
};

export default Whoweare;
