import AboutUs from "../../components/AboutUs/AboutUs";
import EventCarousel from "../../components/EventCarousel/EventCarousel";
import Page from "../../components/Page/Page";
import PastSales from "../../components/PastSales/PastSales";

const Sales = () => {
  return (
    <Page>
      <EventCarousel />
      <PastSales />
      <AboutUs />
    </Page>
  );
};

export default Sales;
