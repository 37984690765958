import { useEffect, useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOut } from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const user = useSelector(store => store.user.user)

  useEffect(() => {
    console.log(user);
  }, [user])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div>
      <Navbar color="white" light expand="md" className="fixed-top">
        <NavbarBrand tag={Link} to="/" className="me-auto">
          <img
            alt="privee"
            src="/assets/img/logo-dark.svg"
            style={{
              width: 140,
            }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="navbar-nav ms-md-5 me-auto mb-2 mb-lg-0 gap-md-3">
            <NavItem>
              <NavLink tag={Link} to="/whoweare">
                WHO WE ARE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/sales">
                OUR SALES
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/howitworks">
                HOW IT WORKS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/signup">
                BECOME A MEMBER
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
            {user?.name && 
            <NavItem>
            
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="" caret>
                {user?.name}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                  <Link to="/profile" className="text-muted text-decoration-none"><FontAwesomeIcon icon={faUser} className="me-2"/> My Profile</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/logout" className="text-muted text-decoration-none"><FontAwesomeIcon icon={faSignOut} className="me-2"/> Logout</Link>
                    </DropdownItem>
                </DropdownMenu>
                  </Dropdown>
              </NavItem>
              
            }

            {!user?.name && 
            <>
            <NavItem>
              <NavLink tag={Link} to="/signin">
                LOGIN
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/signup">
                SIGNUP
              </NavLink>
            </NavItem>
            </>
}
            <NavItem>
              <NavLink tag={Link} to="#">
                <span className="ms-md-2">EN</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
