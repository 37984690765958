import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from "reactstrap";

const Slide1 = () => {
  return (
    <div className="row mt-2 mt-md-5">
      <div className="col-md-6">
        <img src="/assets/img/latest_sale.png" alt="sale" className="img-fluid w-100 h-75" />
      </div>
      <div className="col-md-6">
        <div className="border border-dark p-3 h-75">
          <h3>ABOUT THIS SALE</h3>
          <h6 className="mt-4">Adults winter boots and clothes</h6>
          <p className="mt-4 text-justify">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi iste quidem omnis, sequi dignissimos accusamus consequatur. Animi corporis, repudiandae officiis distinctio excepturi non
            perferendis minima dignissimos provident mollitia vitae nihil cumque dolorem libero odio consequuntur neque debitis sit qui quibusdam veritatis magni praesentium dicta? Odit, voluptate.
            Autem facilis iure reprehenderit?
          </p>
          <div className="row">
            <div className="col-md-6 mt-3">
              <h6>DATES:</h6>
              <div className="mt-3">
                <p className="mb-0">March 11-12</p>
                <p className="mb-0">March 18-19</p>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <h6>OPENING HOURS:</h6>
              <div className="mt-3">
                <p className="mb-0">10am-6pm</p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <span>
                <h6 className="d-inline">LOCATION:</h6>
                9320 Saint-Laurent Blvd, Unit 404, Montreal, QC
              </span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <a href="./calendar.html" className="btn btn-dark rounded-0 px-5 py-2">
                REGISTER
                <i className="ms-2 fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Slide2 = () => {
  return (
    <>
      <div className="carousel-item active">
        <div className="row mt-2 mt-md-5">
          <div className="col-md-6">
            <img src="/assets/img/coming_soon.jpg" alt="sale" className="img-fluid w-100 h-75" />
          </div>
          <div className="col-md-6">
            <div className="border border-dark p-3 h-75">
              <h3>ABOUT THIS SALE</h3>
              <h6 className="mt-4">Adults winter boots and clothes</h6>
              <p className="mt-4 text-justify">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi iste quidem omnis, sequi dignissimos accusamus consequatur. Animi corporis, repudiandae officiis distinctio excepturi
                non perferendis minima dignissimos provident mollitia vitae nihil cumque dolorem libero odio consequuntur neque debitis sit qui quibusdam veritatis magni praesentium dicta? Odit,
                voluptate. Autem facilis iure reprehenderit?
              </p>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <h6>DATES:</h6>
                  <div className="mt-3">
                    <p className="mb-0">March 11-12</p>
                    <p className="mb-0">March 18-19</p>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <h6>OPENING HOURS:</h6>
                  <div className="mt-3">
                    <p className="mb-0">10am-6pm</p>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <span>
                    <h6 className="d-inline">LOCATION:</h6>
                    9320 Saint-Laurent Blvd, Unit 404, Montreal, QC
                  </span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <a href="./calendar.html" className="btn btn-dark rounded-0 px-5 py-2">
                    REGISTER
                    <i className="ms-2 fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const items = [
  {
    id: 1,
    component: <Slide1 />,
  },
  {
    id: 2,
    component: <Slide2 />,
  },
];

function EventCarousel(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem className="custom-tag" tag="div" key={item.id} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
        {item.component}
      </CarouselItem>
    );
  });

  return (
    <div>
      <style>
        {`.custom-tag {
            }`}
      </style>
      <h1 className="text-center">OUR NEXT SALES</h1>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );
}

export default EventCarousel;
